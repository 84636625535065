import * as selectOptions from "@/helpers/selectOptions";
import { capitalize } from "lodash";
import * as dateFns from "date-fns";
import AcknowledgedColumn from "./AcknowledgedColumn.vue";
import PriorityComponent from "./PriorityComponent.vue";

export const tableColumnsListUsers = {
  createdOn: {
    formatDataKey: "Created",
    formatDataValue: (arg: string) =>
      dateFns.format(new Date(arg), "LLLL do, yyyy"),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 0
  },
  expirationDate: {
    formatDataKey: "Expiration Date",
    formatDataValue: (arg: string) =>
      dateFns.format(new Date(arg), "LLLL do, yyyy"),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 1
  },
  title: {
    formatDataKey: "Title",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 2
  },
  priority: {
    formatDataKey: "Priority",
    formatDataValue: (arg: string) => capitalize(arg),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    allowInSimpleFilter: true,
    simpleFilter: selectOptions.userRoles,
    position: 3,
    formatDataComponent: PriorityComponent
  },
  read: {
    formatDataKey: "Read",
    formatDataValue: (arg: any) => (arg ? `YES` : "NO"),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 5,
    colWidth: 100
  },
  userId: {
    formatDataKey: "Acknowledged",
    formatDataValue: (arg: string) => arg,
    formatDataComponent: AcknowledgedColumn,
    allowInAdvancedFilter: false,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    allowInSimpleFilter: false,
    colWidth: 150,
    position: 6
  }
};
