
import Vue from "vue";
export default Vue.extend({
  name: "is-acknowledged-column",
  props: {
    row: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  computed: {
    title(): string {
      return this.notificationAcknowledged ? "YES" : "NO";
    },
    type(): string {
      return this.notificationAcknowledged ? "success" : "danger";
    },
    notificationAcknowledged(): boolean {
      if (this.row) {
        return !!(
          this.row.acknowledgement && this.row.acknowledgement.acknowledgedBy
        );
      }
      return false;
    }
  }
});
