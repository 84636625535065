
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { capitalize } from "lodash";
import Vue from "vue";

export default Vue.extend({
  name: "priority-component",
  components: {
    "font-awesome-icon": FontAwesomeIcon
  },
  props: {
    row: {
      type: Object,
      required: true
    }
  },
  methods: {
    getIcon(priority: string): string {
      if (priority == "high") {
        return "arrow-up";
      } else if (priority == "medium") {
        return "arrows-h";
      } else {
        return "arrow-down";
      }
    },
    getIconClass(priority: string): string {
      if (priority == "high") {
        return "text-red-600";
      } else if (priority == "medium") {
        return "text-yellow-300";
      } else {
        return "text-green-600";
      }
    }
  },
  computed: {
    priority(): string {
      return capitalize(this.row.priority);
    }
  }
});
