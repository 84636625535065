
import Vue from "vue";

import {
  notificationMapActions,
  notificationMapGetters,
  notificationMapState
} from "@/store/modules/notification";
import { ITableWrapperColumns } from "@/types";
import { tableColumnsListUsers } from "./TableColumns";
import { authMapGetters, authMapState } from "@/store/modules/auth";
import * as selectOptions from "@/helpers/selectOptions";
import NoResultsFound from "@/components/NotFound/NoResultsFound.vue";
import { allowToPath } from "@/helpers/generalHelpers";

interface IListUsersData {
  loading: boolean;
  tableActions: any[];
  tableColumns: ITableWrapperColumns;
  showReadNotificationDialog: boolean;
  notificationToRead: string;
  filters: any[];
  tableRowActions: any[];
  errorMessage: string;
  resourceNotFoundMsg: string;
  initialized: boolean;
}

export default Vue.extend({
  name: "ListNotifications",
  components: {
    NoResultsFound
  },
  data(): IListUsersData {
    return {
      loading: false,
      tableActions: [],
      tableColumns: tableColumnsListUsers,
      showReadNotificationDialog: false,
      notificationToRead: "",
      errorMessage: "",
      resourceNotFoundMsg:
        "Ooops! Sorry, you're not authorized to view notifications. Contact your administrator for more information",
      filters: [
        {
          field: "read",
          title: "Read Status",
          options: [
            { label: "Read", value: "true" },
            { label: "Unread", value: "false" }
          ]
        },
        {
          field: "priority",
          title: "Priority",
          options: selectOptions.communicationPriority
        },
        {
          field: "createdOn",
          title: "Created On",
          options: [],
          isDate: true
        }
      ],
      tableRowActions: [{ label: "Read", value: "read", faIcon: "book-open" }],
      initialized: false
    };
  },
  async beforeRouteLeave(to: any, from: any, next: any): Promise<void> {
    if (this.$isCurrentUserAdmin) return next();
    if (allowToPath(to.path)) {
      return next();
    }
    if (this.initData && this.initData.urgentUnreadNotifications.length > 0) {
      this.$modal.show("urgentNotificationModal");
      next(false);
    } else {
      next();
    }
  },
  methods: {
    ...notificationMapActions(["getNotifications"]),
    async fetchPageData(queryObject: any): Promise<void> {
      const sort = queryObject?.__sort;
      if (!sort) {
        queryObject.__sort = "read,-modifiedOn";
      }
      const gtagTimer = this.__gtagMeasureTiming(
        "fetchPageData",
        "users",
        "admin"
      );
      try {
        this.$emit("scrollTop");
        await this.getNotifications({ query: queryObject });
      } catch (error) {
        this.errorMessage = "Error fetching notifications. Try again later";
        this.$bugSnagClient.notify(error);
      } finally {
        if (!this.initialized) {
          this.initialized = true;
        }
        gtagTimer.done();
      }
    },
    async viewCallback(dataReceived: any): Promise<any> {
      this.notificationToRead = dataReceived._id;
      const notificationRoute =
        this.$getCurrentUser.role === "admin"
          ? "/admin/notifications"
          : "/notifications";
      this.$router
        .push(`${notificationRoute}/${this.notificationToRead}`)
        .catch(() => {});
    },
    handleResponseAction(payload: { name: string; data: Record<string, any> }) {
      switch (payload.name) {
        case "read":
          this.viewCallback(payload.data);
          break;
        default:
        //do nothing
      }
    }
  },
  computed: {
    ...notificationMapState(["notifications", "links", "makingApiRequest"]),
    ...notificationMapGetters(["getTotalRecords"]),
    ...authMapState(["initData"]),
    ...authMapGetters(["userHasRoutePermissionDisabled"]),
    userIsAllowedToAccessThisRoute(): boolean {
      return !this.userHasRoutePermissionDisabled("notifications:read");
    }
  }
});
